<template>
  <DashboardTemplate>
    <Layout>
      <el-row type="flex" justify="center" class="mg-y-3">
        <el-col :span="24" v-if="ruleForm">
          <UserInfomation :ruleForm="ruleForm" @saveData="editProfile" />
        </el-col>
      </el-row>
    </Layout>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from "@/template/DashboardTemplate";
import UserInfomation from "@/views/UserInfomation";
import { HTTP } from "@/service/axios";
import Layout from "@/template/LayoutDashboardTemplate";
export default {
  data() {
    return {
      imgUrl: process.env.VUE_APP_IMAGE_URL,
      ruleForm: null,
      originalImg: ""
    };
  },
  components: {
    DashboardTemplate,
    UserInfomation,
    Layout
  },
  computed: {
    memberOf() {
      let data = [];
      this.ruleForm.memberOf.map((value, index) => {
        if (value != 6) {
          let filter = this.$store.state.memberOfOrganize.filter(
            a => a.value == value
          );
          if (filter.length > 0) {
            data.push({ number: filter[0].value, etc: filter[0].label });
          }
        } else {
          data.push({ number: 6, etc: this.ruleForm.memberOfEtc });
        }
      });
      return data;
    },
    user() {
      return this.$store.state.user;
    },
    token() {
      return this.$store.state.token;
    }
  },
  mounted() {
    this.getUserById();
  },
  methods: {
    editProfile(e) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": this.$csrfToken
        }
      };
      let formData = new FormData();
      if (typeof this.$route.params.id != "undefined") {
        formData.append("id", this.ruleForm.id);
      }

      if (typeof this.$route.params.companyId != "undefined") {
        formData.append("companyId", this.$route.params.companyId);
        formData.append("mobilephoneEdit", this.ruleForm.mobilephoneEdit);
        formData.append("emailEdit", this.ruleForm.emailEdit);
      }
      formData.append("userId", this.ruleForm.id);
      formData.append("profileImg", this.originalImg);
      formData.append(
        "file",
        typeof this.ruleForm.profileImg.raw != "undefined"
          ? this.ruleForm.profileImg.raw
          : ""
      );
      formData.append("email", this.ruleForm.email);
      if (this.user.role == 0) {
        formData.append(
          "password",
          typeof this.ruleForm.password != "undefined"
            ? this.ruleForm.password
            : ""
        );
      }

      formData.append(
        "titleName",
        typeof this.ruleForm.titleName != "undefined"
          ? this.ruleForm.titleName
          : 0
      );
      formData.append(
        "name",
        typeof this.ruleForm.name != "undefined" ? this.ruleForm.name : ""
      );
      formData.append(
        "surname",
        typeof this.ruleForm.surname != "undefined" ? this.ruleForm.surname : ""
      );
      formData.append(
        "gender",
        typeof this.ruleForm.gender != "undefined" ? this.ruleForm.gender : 0
      );
      formData.append(
        "businessSize",
        typeof this.ruleForm.businessSize != "undefined"
          ? this.ruleForm.businessSize
          : ""
      );
      formData.append(
        "age",
        typeof this.ruleForm.age != "undefined" ? this.ruleForm.age : 0
      );
      formData.append(
        "education",
        typeof this.ruleForm.education != "undefined"
          ? this.ruleForm.education
          : 0
      );
      formData.append(
        "mobilephone",
        typeof this.ruleForm.mobilephone != "undefined"
          ? this.ruleForm.mobilephone
          : ""
      );
      formData.append(
        "companyName",
        typeof this.ruleForm.companyName != "undefined"
          ? this.ruleForm.companyName
          : ""
      );
      formData.append(
        "companyNameEdit",
        typeof this.ruleForm.companyNameEdit != "undefined" &&
          this.ruleForm.companyNameEdit != null &&
          this.ruleForm.companyNameEdit != ""
          ? this.ruleForm.companyNameEdit
          : ""
      );
      formData.append(
        "corporateNumber",
        typeof this.ruleForm.corporateNumber != "undefined"
          ? this.ruleForm.corporateNumber
          : ""
      );
      formData.append(
        "position",
        typeof this.ruleForm.position != "undefined"
          ? this.ruleForm.position
          : 0
      );
      formData.append(
        "businessType",
        typeof this.ruleForm.businessType != "undefined"
          ? this.ruleForm.businessType
          : 0
      );
      formData.append(
        "cateIndustryType",
        typeof this.ruleForm.cateIndustryType != "undefined" &&
          this.ruleForm.cateIndustryType != null &&
          this.ruleForm.cateIndustryType != ""
          ? this.ruleForm.cateIndustryType
          : 0
      );
      formData.append(
        "industryType",
        typeof this.ruleForm.industryType != "undefined" &&
          this.ruleForm.industryType != null &&
          this.ruleForm.industryType != ""
          ? this.ruleForm.industryType
          : 0
      );
      formData.append(
        "industryTypeEtc",
        typeof this.ruleForm.industryTypeEtc != "undefined"
          ? this.ruleForm.industryTypeEtc
          : ""
      );
      formData.append(
        "memberOf",
        typeof this.ruleForm.memberOf != "undefined"
          ? JSON.stringify(this.memberOf)
          : ""
      );
      formData.append(
        "memberOfEtc",
        typeof this.ruleForm.memberOfEtc != "undefined"
          ? this.ruleForm.memberOfEtc
          : ""
      );
      formData.append(
        "employment",
        typeof this.ruleForm.employment != "undefined"
          ? this.ruleForm.employment
          : 0
      );
      formData.append(
        "yearEstablished",
        typeof this.ruleForm.yearEstablished != "undefined"
          ? Number(this.ruleForm.yearEstablished)
          : ""
      );
      formData.append(
        "latestAnnualRevenues",
        typeof this.ruleForm.latestAnnualRevenues != "undefined"
          ? this.ruleForm.latestAnnualRevenues
          : 0
      );
      formData.append(
        "latestAnnualProfits",
        typeof this.ruleForm.latestAnnualProfits != "undefined"
          ? this.ruleForm.latestAnnualProfits
          : 0
      );
      formData.append(
        "exportExperience",
        typeof this.ruleForm.exportExperience != "undefined" &&
          this.ruleForm.exportExperience != null &&
          this.ruleForm.exportExperience != ""
          ? this.ruleForm.exportExperience
          : 0
      );
      formData.append(
        "exportRatio",
        typeof this.ruleForm.exportRatio != "undefined" &&
          this.ruleForm.exportRatio != null &&
          this.ruleForm.exportRatio != ""
          ? this.ruleForm.exportRatio
          : 0
      );
      formData.append(
        "exportSales",
        typeof this.ruleForm.exportSales != "undefined" &&
          this.ruleForm.exportSales != null &&
          this.ruleForm.exportSales != ""
          ? this.ruleForm.exportSales
          : 0
      );
      formData.append(
        "exportFrequency",
        typeof this.ruleForm.exportFrequency != "undefined" &&
          this.ruleForm.exportFrequency != null &&
          this.ruleForm.exportFrequency != ""
          ? this.ruleForm.exportFrequency
          : 0
      );
      formData.append(
        "orderConsistency",
        typeof this.ruleForm.orderConsistency != "undefined" &&
          this.ruleForm.orderConsistency != null &&
          this.ruleForm.orderConsistency != ""
          ? this.ruleForm.orderConsistency
          : 0
      );
      formData.append(
        "exportCountry",
        typeof this.ruleForm.exportCountry != "undefined" &&
          this.ruleForm.exportCountry != null &&
          this.ruleForm.exportCountry != ""
          ? this.ruleForm.exportCountry
          : 0
      );
      formData.append(
        "trainingExpectations",
        typeof this.ruleForm.trainingExpectations != "undefined" &&
          this.ruleForm.trainingExpectations != null &&
          this.ruleForm.trainingExpectations != ""
          ? this.ruleForm.trainingExpectations
          : 0
      );
      formData.append(
        "moreExportExpectations",
        typeof this.ruleForm.moreExportExpectations != "undefined" &&
          this.ruleForm.moreExportExpectations != null &&
          this.ruleForm.moreExportExpectations != ""
          ? this.ruleForm.moreExportExpectations
          : 0
      );
      formData.append(
        "expectedPercentage",
        typeof this.ruleForm.expectedPercentage != "undefined" &&
          this.ruleForm.expectedPercentage != null &&
          this.ruleForm.expectedPercentage != ""
          ? this.ruleForm.expectedPercentage
          : 0
      );
      formData.append(
        "expectedPercentageEtc",
        typeof this.ruleForm.expectedPercentageEtc != "undefined" &&
          this.ruleForm.expectedPercentageEtc != "" &&
          this.ruleForm.expectedPercentageEtc != null
          ? this.ruleForm.expectedPercentageEtc
          : 0
      );
      formData.append(
        "consent",
        typeof this.ruleForm.consent != "undefined" ? this.ruleForm.consent : 0
      );
      formData.append("_csrf", this.$csrfToken);
      formData.append(
        "operatorType",
        typeof this.ruleForm.operatorType != "undefined" &&
          this.ruleForm.operatorType != null &&
          this.ruleForm.operatorType != ""
          ? this.ruleForm.operatorType
          : 0
      );
      formData.append(
        "exportPlan",
        typeof this.ruleForm.exportPlan != "undefined" &&
          this.ruleForm.exportPlan != "" &&
          this.ruleForm.exportPlan != null
          ? this.ruleForm.exportPlan
          : 0
      );
      formData.append(
        "isProduction",
        typeof this.ruleForm.isProduction != "undefined"
          ? this.ruleForm.isProduction
          : ""
      );
      formData.append(
        "shipment",
        typeof this.ruleForm.shipment != "undefined" &&
          this.ruleForm.shipment != "" &&
          this.ruleForm.shipment != null
          ? this.ruleForm.shipment
          : 0
      );
      formData.append(
        "paymentTerm",
        typeof this.ruleForm.paymentTerm != "undefined" &&
          this.ruleForm.paymentTerm != "" &&
          this.ruleForm.paymentTerm != null
          ? this.ruleForm.paymentTerm
          : 0
      );
      formData.append(
        "exportValue",
        typeof this.ruleForm.exportValue != "undefined" &&
          this.ruleForm.exportValue != "" &&
          this.ruleForm.exportValue != null
          ? this.ruleForm.exportValue
          : 0
      );

      let url = "user";
      if (typeof this.$route.params.id != "undefined") {
        url = `company/information`;
      }
      HTTP.put(url, formData, config)
        .then(res => {
          if (res.data.success) {
            this.loading = false;
            this.$message({
              message: "แก้ไขข้อมูลสำเร็จ",
              type: "success"
            });
            this.getUserById(true);
          } else {
            this.loading = false;
            this.$message.error(res.data.data);
          }
        })
        .catch(e => {
          this.$message.error(e);
          this.loading = false;
          console.log(`put/user error`, e);
        });
    },
    getUserById ( edit = "" )
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let url = "user";

      if (
        typeof this.$route.params.id != "undefined" &&
        typeof this.$route.params.companyId != "undefined"
      ) {
        url = `/information/${this.$route.params.id}/${this.$route.params.companyId}`;
      }
      HTTP.get(url)
        .then(res => {
          if (res.data.success) {
            //console.log(url, res);
            this.ruleForm = JSON.parse(JSON.stringify(res.data.obj));
            this.ruleForm.memberOf = [];

            res.data.obj.memberOf.map(data => {
              this.ruleForm.memberOf.push(data.memberOf);
              if (data.memberOf == 6) {
                this.ruleForm.memberOfEtc = data.memberOfEtc;
              } else if (data.memberOf == 7) {
                this.ruleForm.memberOfEtc = "EXAC";
              }
            });

            res.data.obj.operatorType == 0 || res.data.obj.operatorType == ""
              ? (this.ruleForm.operatorType = null)
              : null;
            if (res.data.obj.exportRatio == 0) {
              this.ruleForm.exportRatio = "";
            }
            if (res.data.obj.operatorType == 2) {
              this.ruleForm.operatorType = "";
            }
            if (this.ruleForm.companyNameEdit == "") {
              this.ruleForm.companyNameEdit = this.ruleForm.companyName;
            }

            this.ruleForm.oldCompanyNumber = this.ruleForm.corporateNumber;

            if (typeof this.$route.params.id != "undefined") {
              this.ruleForm.role = 0;
            } else {
              this.originalImg = res.data.obj.profileImg;
              this.$store.commit(
                "SET_USER",
                JSON.parse(JSON.stringify(res.data.obj))
              );
            }
            this.originalImg = res.data.obj.profileImg;
            if (edit) {
              if (typeof this.$route.params.id != "undefined") {
                // this.$router.push("/user/list");
              } else if (
                res.data.obj.recommendStatus == 0 ||
                res.data.obj.recommendStatus == 2
              ) {
                let url = "/factor";
                typeof this.$route.query.code != "undefined"
                  ? (url = url + "?code=" + this.$route.query.code)
                  : null;
                this.$router.push(url).catch(err => {});
              } else {
                let url = "/result";
                typeof this.$route.query.code != "undefined"
                  ? (url = url + "?code=" + this.$route.query.code)
                  : null;
                this.$router.push(url).catch(err => {});
              }
            }
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("fetchUserList error", e);
        });
    }
  }
};
</script>
